// components/admin/golf/GameStatusControl.js
import React, { useEffect } from 'react';
import { Button } from '../../ui/form';
import { ErrorMessage, LoadingSpinner } from '../../ui/state';
import useGameStatusManagement from '../../../hooks/golf/useGameStatusManagement';
import Card from '../shared/Card';

const GameStatusControl = ({ game, onStatusChange }) => {
  // React Hooks must be called unconditionally at the top level
  const {
    loading,
    error,
    syncMessage,
    statusOptions,
    selectedStatus,
    setSelectedStatus,
    currentStatus,
    updateGameStatus,
    refreshStatusOptions
  } = useGameStatusManagement(game?._id || '');

  // Call parent callback when status changes
  useEffect(() => {
    if (syncMessage && syncMessage.includes('successfully') && onStatusChange) {
      console.log('Status updated successfully, calling onStatusChange');
      onStatusChange();
    }
  }, [syncMessage, onStatusChange]);
  
  // Early check for valid game - but AFTER hooks are called
  if (!game || !game._id) {
    console.log('GameStatusControl: No valid game provided', game);
    return (
      <Card title="Game Status Management">
        <p className="text-gray-500">No active game found.</p>
      </Card>
    );
  }
  
  // Add explicit logging to help debug
  console.log('GameStatusControl rendering with game:', { 
    id: game._id, 
    name: game.tournamentName, 
    status: game.status 
  });

  if (loading && !currentStatus) {
    return <LoadingSpinner />;
  }

  // If no transitions are available
  if (statusOptions.length === 0 && !loading && !error) {
    return (
      <Card title="Game Status Management">
        <h3 className="font-medium">Game Status: {currentStatus || game.status}</h3>
        <p className="text-sm text-gray-500 mt-1">
          No status transitions available for this game.
        </p>
      </Card>
    );
  }

  return (
    <Card title="Game Status Management">
      <div className="mb-4">
        <div className="text-sm font-medium text-gray-500 mb-1">Current Status</div>
        <div className="font-semibold text-lg">
          {currentStatus || game.status}
        </div>
      </div>

      {error && <ErrorMessage message={error} />}
      
      {syncMessage && (
        <div className="bg-green-50 border border-green-200 text-green-700 p-4 rounded-lg mb-4">
          {syncMessage}
        </div>
      )}
      
      <div className="mt-3">
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Change Status
        </label>
        <div className="flex items-end gap-3">
          <div className="flex-grow">
            <select
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
              disabled={loading || statusOptions.length === 0}
              className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500/20 focus:border-blue-500"
            >
              <option value="">Select new status...</option>
              {statusOptions.map(status => (
                <option key={status} value={status}>
                  {status.charAt(0).toUpperCase() + status.slice(1)}
                </option>
              ))}
            </select>
          </div>
          
          <Button
            onClick={updateGameStatus}
            disabled={!selectedStatus || loading}
            isLoading={loading}
          >
            Update
          </Button>
        </div>
      </div>
      
      {(currentStatus || game.status) === 'upcoming' && game.deadline && (
        <div className="mt-4 p-3 bg-blue-50 border border-blue-100 rounded-lg text-sm">
          <div className="font-medium text-blue-800">Upcoming Game Info</div>
          <div className="text-blue-600 mt-1">
            <span className="font-medium">Deadline:</span> {new Date(game.deadline).toLocaleString()}
          </div>
          <div className="text-blue-600 mt-1 italic">
            Game will automatically transition to "active" after the deadline passes.
          </div>
        </div>
      )}
    </Card>
  );
};

export default GameStatusControl;