// src/components/layout/footer/index.js
import React, { useState, useEffect } from 'react';
import { useFeatureFlags } from '../../../context/FeatureFlagsContext';
import { FooterSection } from './FooterSection';
import { FooterLink } from './FooterLink';
import { layout } from '../../../utils/layout';
import { cn } from '../../../utils/cn';

const SimpleFooter = () => (
  <footer className={layout.colors.primary.main}>
    <div className={cn(layout.container, 'py-6')}>
      <div className="text-center text-white">
        <p>Copyright {new Date().getFullYear()} Sports Predictions. All rights reserved.</p>
      </div>
    </div>
  </footer>
);

const Footer = () => {
  const [pages, setPages] = useState([]);
  const [loading, setLoading] = useState(true);
  // Get feature flags
  const flags = useFeatureFlags();
  const { isMainSite } = flags;

  useEffect(() => {
    const loadPages = async () => {
      if (!isMainSite) {
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(
          `${process.env.REACT_APP_STRAPI_URL}/api/static-pages?populate=footer_menu`
        );
        const data = await response.json();
        setPages(data.data.filter(page => page.ShowinFooter));
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    };

    loadPages();
  }, [isMainSite]);

  if (loading) return null;
  if (!isMainSite) return <SimpleFooter />;

  const getPagesByMenuTitle = (menuTitle) =>
    pages.filter(page => page.footer_menu?.Title === menuTitle);

  const aboutPages = getPagesByMenuTitle('About Us');
  const legalPages = getPagesByMenuTitle('Legal');

  return (
    <footer className={layout.colors.primary.main}>
      <div className={cn(layout.container, layout.spacing.footer.wrapper)}>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <FooterSection title="About" pages={aboutPages} />
          
          <div>
            <h3 className="font-semibold mb-4 text-white">Games</h3>
            <ul className={layout.spacing.footer.section}>
              <li>
                <FooterLink to="/football">
                  Football - 5 Scorers
                </FooterLink>
              </li>
              {flags.SHOW_NFLSC && (
                <li>
                  <FooterLink to="/nfl-sc">
                    NFL Super Contest
                  </FooterLink>
                </li>
              )}
            </ul>
          </div>

          <FooterSection title="Legal" pages={legalPages} />
        </div>
        
        <div className={cn(
          layout.spacing.footer.divider,
          'border-t',
          layout.colors.footer.border,
          'text-center text-white'
        )}>
          <p>Copyright {new Date().getFullYear()} Sports Predictions. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;