import React from 'react';
import { useFeatureFlags } from '../../../context/FeatureFlagsContext';
import GameCard from '../../common/GameCard';
import { gameTypeColors } from '../../../utils/colors';

const GameSection = ({ currentGame, rugbyGame, golfGame, isMainSite }) => {
  const flags = useFeatureFlags();

  // Create an array of game objects based on feature flags
  const games = [];
  
  // Add football game
  if (flags.SHOW_FOOTBALL) {
    games.push({
      title: "Football - 5 Scorers",
      deadline: currentGame?.deadline,
      links: [{ to: "/football", text: "Play Game" }],
      type: 'football'
    });
  }
  
  // Add NFL Super Contest game
  if (isMainSite && flags.SHOW_NFLSC) {
    games.push({
      title: "NFL Super Contest",
      links: [{ to: "/nfl-sc", text: "Play Game" }],
      type: 'nfl-sc'
    });
  }
  
  // Add Rugby game
  if (isMainSite && flags.SHOW_RUGBY) {
    games.push({
      title: "Six Nations",
      deadline: rugbyGame?.deadline,
      links: [{ to: "/rugby", text: "Play Now" }],
      type: 'rugby'
    });
  }
  
  // Add Golf game
  if (isMainSite && flags.SHOW_GOLF) {
    games.push({
      title: "Golf Tournament",
      deadline: golfGame?.deadline,
      links: [{ to: "/golf", text: "Play Now" }],
      type: 'golf'
    });
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      {games.map((game, index) => (
        <div key={index} className="w-full">
          <GameCard 
            {...game}
            style="detailed" 
            showPrizePool={true}
          />
        </div>
      ))}
    </div>
  );
};

export default GameSection;