import axios from 'axios';

export const API_CONFIG = {
  baseURL: process.env.REACT_APP_API_URL,
  defaultHeaders: {
    'Content-Type': 'application/json',
  },
  timeout: 30000, // 30 seconds
};

export const GAME_VARIANTS = {
  FOOTBALL: 'football',
  NFL_LMS: 'nfl-lms',
  NFL_SC: 'nfl-sc',
  GOLF: 'golf',
  RUGBY: 'rugby',
};

export const ERROR_MESSAGES = {
  NETWORK_ERROR: 'Network error. Please check your connection.',
  UNAUTHORIZED: 'You are not authorized to perform this action.',
  SERVER_ERROR: 'An unexpected server error occurred.',
  NOT_FOUND: 'The requested resource was not found.',
  VALIDATION_ERROR: 'Please check your input and try again.',
};

export const RESPONSE_MESSAGES = {
  CREATE_SUCCESS: 'Successfully created.',
  UPDATE_SUCCESS: 'Successfully updated.',
  DELETE_SUCCESS: 'Successfully deleted.',
  SYNC_SUCCESS: 'Data synchronized successfully.',
};

// Fixed to prevent double /api
export const fetchFeatureFlags = async () => {
  try {
    // Get the base URL without trailing slash
    const baseURL = (process.env.REACT_APP_API_URL || '').replace(/\/$/, '');
    
    // Check if baseURL already contains /api
    const endpoint = baseURL.includes('/api') 
      ? `${baseURL}/config/feature-flags` 
      : `${baseURL}/api/config/feature-flags`;
    
    console.log('Fetching feature flags from:', endpoint);
    const response = await axios.get(endpoint);
    return response.data;
  } catch (error) {
    console.error('Error fetching feature flags:', error);
    throw error;
  }
};