import React, { createContext, useContext, useState, useEffect } from 'react';
import { fetchFeatureFlags } from '../api/config';

const FeatureFlagsContext = createContext();

export const FeatureFlagsProvider = ({ children }) => {
  // Get build-time flags from process.env
  const buildTimeFlags = {
    SHOW_FOOTBALL: process.env.REACT_APP_SHOW_FOOTBALL === 'true',
    SHOW_AMERICAN_FOOTBALL: process.env.REACT_APP_SHOW_AMERICAN_FOOTBALL === 'true',
    SHOW_NFLSC: process.env.REACT_APP_SHOW_NFLSC === 'true',
    SHOW_GOLF: process.env.REACT_APP_SHOW_GOLF === 'true',
    SHOW_RUGBY: process.env.REACT_APP_SHOW_RUGBY === 'true',
    isMainSite: process.env.REACT_APP_SITE_TYPE === 'main',
    siteType: process.env.REACT_APP_SITE_TYPE
  };
  
  console.log('Build-time feature flags:', buildTimeFlags);
  console.log('Raw environment variables:', {
    SHOW_FOOTBALL: process.env.REACT_APP_SHOW_FOOTBALL,
    SHOW_AMERICAN_FOOTBALL: process.env.REACT_APP_SHOW_AMERICAN_FOOTBALL,
    SHOW_NFLSC: process.env.REACT_APP_SHOW_NFLSC,
    SHOW_GOLF: process.env.REACT_APP_SHOW_GOLF,
    SHOW_RUGBY: process.env.REACT_APP_SHOW_RUGBY,
    SITE_TYPE: process.env.REACT_APP_SITE_TYPE
  });
  
  // Start with build-time defaults
  const [flags, setFlags] = useState(buildTimeFlags);
  const [loading, setLoading] = useState(false); // Changed to false initially
  const [error, setError] = useState(null);

  // For now, let's use build-time flags directly
  // We'll implement the server-side part later
  /*
  useEffect(() => {
    const loadFeatureFlags = async () => {
      try {
        setLoading(true);
        // Fetch runtime flags from server
        const serverFlags = await fetchFeatureFlags();
        console.log('Server feature flags:', serverFlags);
        
        // Update with server values
        setFlags(serverFlags);
      } catch (error) {
        console.error('Failed to fetch feature flags, using build-time defaults:', error);
        setError(error);
        // We're already using build-time defaults, so just log the error
      } finally {
        setLoading(false);
      }
    };

    loadFeatureFlags();
  }, []);
  */

  // THIS IS THE CRITICAL FIX - properly provide the context value
  return (
    <FeatureFlagsContext.Provider value={{ ...flags, loading, error }}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};

export const useFeatureFlags = () => {
  const context = useContext(FeatureFlagsContext);
  if (!context) {
    throw new Error('useFeatureFlags must be used within a FeatureFlagsProvider');
  }
  return context;
};