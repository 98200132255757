import BaseApi, { API_ENDPOINTS } from '../../base/baseApi';

console.log('Starting Golf Admin Service setup...');

class GolfAdminService extends BaseApi {
    async getCurrentGame() {
        try {
            const response = await this.get(API_ENDPOINTS.admin.golf.currentGame);
            console.log('Golf admin service response:', response);
            
            // Handle both formats - if response has data property, it's the new format
            if (response?.data) {
                return response.data; // The new format already has the proper structure
            }
            
            // For old format, return in the expected structure
            return {
                currentGame: response
            };
        } catch (error) {
            console.error('Error in getCurrentGame:', error);
            throw error;
        }
    }

    async createGame(tournamentData) {
        try {
            console.log('Creating new tournament:', tournamentData);
            const response = await this.post(API_ENDPOINTS.admin.golf.games, tournamentData);
            console.log('Tournament creation response:', response);
            return response;
        } catch (error) {
            console.error('Error creating new tournament:', error);
            throw error;
        }
    }

    async syncPlayers() {
        try {
            const response = await this.post(API_ENDPOINTS.admin.golf.sync);
            console.log('Player sync response:', response);
            return response;
        } catch (error) {
            console.error('Error syncing players:', error);
            throw error;
        }
    }

    async getAvailablePlayers() {
        try {
            const response = await this.get(API_ENDPOINTS.admin.golf.players);
            console.log('Available players response:', response);
            
            // Handle different response formats
            if (Array.isArray(response)) {
                return response;
            } else if (Array.isArray(response?.data)) {
                return response.data;
            } else if (Array.isArray(response?.data?.players)) {
                return response.data.players;
            }
            
            // If none of the above patterns match, return empty array
            console.warn('Unexpected players response format:', response);
            return [];
        } catch (error) {
            console.error('Error getting available players:', error);
            throw error;
        }
    }

    async syncTournament() {
        try {
            const response = await this.post(API_ENDPOINTS.admin.golf.syncTournament);
            console.log('Tournament sync response:', response);
            return response;
        } catch (error) {
            console.error('Error syncing tournament:', error);
            throw error;
        }
    }

    async getPlayerMappings() {
        try {
            const response = await this.get(API_ENDPOINTS.admin.golf.playerMappings);
            
            // Handle different response formats
            if (response?.data) {
                return response.data;
            }
            
            return response || { mappings: [], unmatchedNames: [] };
        } catch (error) {
            console.error('Error getting player mappings:', error);
            throw error;
        }
    }

    async addPlayerMapping(canonicalName, variations) {
        try {
            const response = await this.post(API_ENDPOINTS.admin.golf.playerMappings, {
                canonicalName,
                variations
            });
            return response;
        } catch (error) {
            console.error('Error adding player mapping:', error);
            throw error;
        }
    }

    async removePlayerMapping(canonicalName) {
        try {
            const response = await this.delete(API_ENDPOINTS.admin.golf.playerMappings, {
                data: { canonicalName }
            });
            return response;
        } catch (error) {
            console.error('Error removing player mapping:', error);
            throw error;
        }
    }

    async updatePlayerOdds(playerId, odds) {
        try {
            const response = await this.put(
                API_ENDPOINTS.admin.golf.updateOdds(playerId),
                { odds }
            );
            return response;
        } catch (error) {
            console.error('Error updating player odds:', error);
            throw error;
        }
    }

    async appendPlayers() {
        try {
          const response = await this.post(API_ENDPOINTS.admin.golf.appendPlayers);
          console.log('Append players response:', response);
          return response?.data || response;
        } catch (error) {
          console.error('Error appending players:', error);
          throw error;
        }
      }

    async createLeague(name) {
        try {
            const response = await this.getCurrentGame();
            console.log('Current game response in createLeague:', response);
            
            if (!response?.currentGame) {
                console.error('No current game data found:', response);
                throw new Error('No active tournament found');
            }
            
            const currentGame = response.currentGame;
            const leagueData = {
                name,
                game: currentGame._id,
                tournamentName: currentGame.name,
                status: 'active'
            };
            
            console.log('About to send league creation request with data:', JSON.stringify(leagueData, null, 2));
            const leagueResponse = await this.post(API_ENDPOINTS.admin.golf.leagues, leagueData);
            console.log('League creation response:', leagueResponse);
            return leagueResponse?.data;
        } catch (error) {
            console.error('Error in createLeague:', error);
            throw error;
        }
    }

    async getLeaguesForGame(gameId) {
        try {
          console.log("1. getLeaguesForGame called with gameId:", gameId);
          
          if (!gameId) {
            const currentGame = await this.getCurrentGame();
            console.log("2. Current game object:", currentGame);
            
            if (!currentGame?._id) {
              console.log("3. No current game found with _id");
              return [];
            }
            
            gameId = currentGame._id;
            console.log("4. Using game ID from current game:", gameId);
          }
          
          const url = `${API_ENDPOINTS.admin.golf.leagues}?gameId=${gameId}`;
          console.log("5. Making API request to:", url);
          
          const response = await this.get(url);
          console.log("6. API response:", response);
          
          const leagues = response?.data || [];
          console.log("7. Extracted leagues:", leagues);
          
          return leagues;
        } catch (error) {
          console.error("ERROR in getLeaguesForGame:", error);
          return [];
        }
      }

/**
 * Get available status transitions for a game
 * @param {string} gameId - The ID of the game
 * @returns {Promise<Object>} - Object containing currentStatus and validTransitions
 */
async getGameStatusOptions(gameId) {
    try {
      // Early return if gameId is not provided
      if (!gameId) {
        console.error('getGameStatusOptions called without a gameId');
        return { currentStatus: '', validTransitions: [] };
      }
      
      console.log('Fetching game status options for game ID:', gameId);
      const response = await this.get(`/admin/golf/games/${gameId}/status-options`);
      
      // Add debug logging
      console.log('Game status options response:', response);
      
      return response.data || response;
    } catch (error) {
      console.error('Error getting game status options:', error);
      
      // Return empty data instead of throwing
      return { currentStatus: '', validTransitions: [] };
    }
  }
  
  async updateGameStatus(gameId, status) {
    try {
      console.log('Updating game status:', { gameId, status });
      const response = await this.post(`/admin/golf/games/${gameId}/status`, { status });
      return response.data || response;
    } catch (error) {
      console.error('Error updating game status:', error);
      throw error;
    }
  }

}

const golfAdminService = new GolfAdminService();
console.log('Created golfAdminService instance:', golfAdminService);
console.log('Instance methods:', Object.getOwnPropertyNames(GolfAdminService.prototype));
export { golfAdminService };