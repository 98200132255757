// hooks/golf/useGameStatusManagement.js
import { useState, useEffect, useCallback, useRef } from 'react';
import { golfAdminService } from '../../api/services';
import useGolfAdminBase from './useGolfAdminBase';

const useGameStatusManagement = (gameId) => {
  const { loading, error, syncMessage, setLoading, setError, setSyncMessage, handleError } = useGolfAdminBase();
  const [statusOptions, setStatusOptions] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [currentStatus, setCurrentStatus] = useState('');
  
  // Use a ref to track if we've already loaded options for this gameId
  const loadedGameIdRef = useRef(null);
  const loadingRef = useRef(false);

  // Use useCallback to memoize the function
  const loadStatusOptions = useCallback(async () => {
    // Skip if we're already loading or gameId is invalid
    if (!gameId || loadingRef.current) {
      return;
    }
    
    // Skip if we've already loaded for this gameId
    if (loadedGameIdRef.current === gameId) {
      return;
    }
    
    try {
      loadingRef.current = true;
      setLoading(true);
      setError(null);
      
      console.log('Fetching status options for game:', gameId);
      
      const response = await golfAdminService.getGameStatusOptions(gameId);
      console.log("Status options response:", response);
      
      if (response && !response.error) {
        setStatusOptions(response.validTransitions || []);
        setCurrentStatus(response.currentStatus || '');
        
        // Update our ref to track that we've loaded for this gameId
        loadedGameIdRef.current = gameId;
      } else {
        console.error('Invalid response from getGameStatusOptions:', response);
        setStatusOptions([]);
        setError('Failed to load status options');
      }
      
    } catch (err) {
      handleError(err, 'Failed to load status options');
      setStatusOptions([]);
    } finally {
      setLoading(false);
      loadingRef.current = false;
    }
  }, [gameId, setLoading, setError, handleError]);

  // Only load on initial render or when gameId changes
  useEffect(() => {
    if (gameId) {
      // Reset the loadedGameId if gameId changes
      if (loadedGameIdRef.current !== gameId) {
        loadedGameIdRef.current = null;
      }
      loadStatusOptions();
    } else {
      // Reset state if no gameId is provided
      setStatusOptions([]);
      setCurrentStatus('');
    }
  }, [gameId, loadStatusOptions]);

  const updateGameStatus = async () => {
    if (!selectedStatus || !gameId) {
      console.log('Cannot update status: missing status or gameId');
      return;
    }
    
    try {
      setLoading(true);
      setSyncMessage('Updating game status...');
      
      console.log('Updating game status:', { gameId, newStatus: selectedStatus });
      await golfAdminService.updateGameStatus(gameId, selectedStatus);
      
      setSyncMessage(`Game status updated to "${selectedStatus}" successfully!`);
      setSelectedStatus('');
      
      // Force reload options after updating status
      loadedGameIdRef.current = null;
      await loadStatusOptions();
      
    } catch (err) {
      handleError(err, 'Failed to update game status');
    } finally {
      setLoading(false);
    }
  };

  const forceRefresh = useCallback(() => {
    loadedGameIdRef.current = null;
    loadStatusOptions();
  }, [loadStatusOptions]);

  return {
    loading,
    error,
    syncMessage,
    statusOptions,
    selectedStatus,
    setSelectedStatus,
    currentStatus,
    updateGameStatus,
    refreshStatusOptions: forceRefresh
  };
};

export default useGameStatusManagement;